<template>
    <div class="w-full flex">
        <div class="w-2/3">
            <vx-card>
                <div class="w-full flex">
                    <div class="w-1/12">Date: </div>
                    <div class="w-11/12"><flat-pickr v-model.lazy="selectDate" :config="configdateTimePicker" class="w-full" name="selectDate" /></div>
                </div>
                <div class="w-full flex mt-2">
                    <div class="w-1/12">De: </div>
                    <div class="w-11/12">{{ from }}</div>
                </div>
                <div class="w-full flex mt-2">
                    <div class="w-1/12">A:</div>
                    <div class="w-11/12 p-4 rounded-lg" style="border: 1px dashed gray;">
                        <span :key="index" class="p-2 mr-2 text-white rounded-lg" style="background-color: green;color:white;" v-for="(contact,index) in a">
                            <a class="text-white mr-1">{{ contact.prenoms }}</a>
                            <a class="text-white mr-1">{{ contact.nom }}</a>
                            <a class="text-white">[{{ contact.telephone }}]</a>
                            <a @click="removeContact(index)" class="text-black font-semibold cursor-pointer">x</a>
                        </span>
                    </div>
                </div>
                <div>
                    <div class="w-full flex justify-between text-right mt-4">
                        <vx-tooltip
                            color="#000000"
                            text="Vous pouvez faire figurer le nom ou les prénoms du client en utilisant les crochets, Exemple: Hello [Nom] -> Hello Koffi ou Nom = Koffi ;"
                            >
                            <feather-icon class="ml-1" icon="InfoIcon" />
                        </vx-tooltip>
                        <span>
                            {{messageCount}} message(s) / {{ charactersRemaining }} caractères restant
                        </span>
                    </div>
                    <div class="w-full">
                        <vs-textarea class="w-full" v-model="text" label="message" row="12" />
                    </div>
                </div>
                <div class="flex justify-end">
                    <vs-button ref="loadableButton" id="button-with-loading" class="vs-con-loading__container" @click="sendAnnivClientSMSMessage()">Lancer la campagne</vs-button>
                </div>
                <vs-table class="mt-2" stripe noDataText="Aucune client" pagination max-items="20" search :data="clients">
                    <template slot="header">
                        <vs-button class="mb-4 mr-5 md:mb-0" @click="addAllContact()">Ajouter tous les clients à la liste d'envoi</vs-button>
                    </template>
                    <template slot="thead">
                        <vs-th sort-key="_id">
                            N°
                        </vs-th>
                        <vs-th sort-key="nom">Nom</vs-th>
                        <vs-th sort-key="prenoms">Prénoms</vs-th>
                        <vs-th sort-key="telephone">Téléphone</vs-th>
                        <vs-th sort-key="statusMessage"></vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <tbody>
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td>
                                    {{ indextr + 1 }}
                                </vs-td>

                                <vs-td>
                                    {{ tr.Nom}}
                                </vs-td>

                                <vs-td>
                                    {{ tr.Prenoms }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.Adresse ? tr.Adresse.mobile : '' }}
                                </vs-td>
                                <vs-td class="whitespace-no-wrap">
                                    <vs-button icon-pack="feather" icon="icon-plus" @click="addContact(tr)"></vs-button>
                                </vs-td>
                            </vs-tr>
                        </tbody>
                    </template>
                </vs-table>
            </vx-card>
        </div>
        <div class="w-1/3">
            <div class="analyze-preview-container" style="margin-left: 200px;">
                <div class="phone-container sms ios wl ">
                <div class="phone-content">
                    <p class="sender fs-exclude">{{from}}</p>
                    <p class="msg-details">Message texte<br>ven. 4 févr. {{(new Date().getFullYear())}} {{new Date().getHours()}}:{{new Date().getMinutes()}}</p>
                    <div class="msg-container fs-exclude">
                    <span dir="auto" class="msg-content">
                        {{text}}
                    </span>
                    </div>
                </div>
                </div>
            </div>
            <!-- <vx-card>
            </vx-card> -->
        </div>
    </div>
</template>
<script>
/* lazy loading Vue components */
const flatPickr = () => import('vue-flatpickr-component')
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'
import 'flatpickr/dist/flatpickr.css'
export default {
  name: 'anniv_client',
  components: {
    flatPickr
  },
  data () {
    return {
      idOfficine: null,
      activeUserInfos: null,

      IdUser: null,
      from: '',
      senderAdress: '2250777219794',
      text: '',
      destinations: [],
      a: [],
      selectDate: new Date(),
      Day: (new Date()).getDate(),
      Month: (new Date()).getMonth(),
      charactersPerMessage: 160,
      singleDestination: '',
      oldMessageLength: 0,
      currentMessageLength: 0,
      courtMessage: '',
      longMessage: '',
      restMessage: '',
      messageLengthArray: [],

      backgroundLoading:'primary',
      colorLoading:'#fff',
      configdateTimePicker: {
        dateFormat: 'd F Y',
        locale: FrenchLocale
      }
    }
  },
  watch: {
    selectDate () {
      this.getClientsWithBirthdayToday()
    }
  },
  computed: {
    clients () {
      return this.$store.state.client.clientsBirthday
    },
    messageCount () {
      const messageLength = this.text ? this.text.length : 0
      return Math.ceil(messageLength / this.charactersPerMessage)
    },
    charactersRemaining () {
      const messageLength = this.text ? this.text.length : 0
      const remainingCharacters = this.charactersPerMessage - (messageLength % this.charactersPerMessage)
      return remainingCharacters
    }
  },
  methods: {
    countMessage () {
      let messageLength = 160
      if (messageLength > 0) {
        messageLength = messageLength - (this.messageCourt ? this.messageCourt : 0)
        return messageLength
      } else {
        messageLength = 160
        return messageLength
      }

    },
    addAllContact () {
      this.a = []
      this.clients.map((client) => {
        const indicatifPays = '225' // Remplacez par l'indicatif pays souhaité (sans le signe +)

        const telephone = client.Adresse && client.Adresse.mobile ? client.Adresse.mobile.startsWith(indicatifPays) ? client.Adresse.mobile : indicatifPays + client.Adresse.mobile : ''

        this.a.push({
          _id: client._id,
          nom: client.Nom,
          prenoms: client.Prenoms,
          telephone,
          email: client.Adresse ? client.Adresse.email : '',
          statusMessage: null,
          statusCode: null,
          resourceURL: null
        })
      })
    },
    addContact (contact) {
      const indicatifPays = '225' // Remplacez par l'indicatif pays souhaité (sans le signe +)

      const telephone = contact.Adresse && contact.Adresse.mobile ? contact.Adresse.mobile.startsWith(indicatifPays) ? contact.Adresse.mobile : indicatifPays + contact.Adresse.mobile : ''
      this.a.push({
        _id: contact._id,
        nom: contact.Nom,
        prenoms: contact.Prenoms,
        telephone,
        email: contact.Adresse ? contact.Adresse.email : '',
        statusMessage: null,
        statusCode: null,
        resourceURL: null
      })
    },
    removeContact (index) {
      this.a.splice(index, 1)
    },
    sendAnnivClientSMSMessage () {
      if (this.a.length > 0) {
        const payload = {
          a: this.a,
          messageTemplate: this.text,
          senderAdress: this.senderAdress,
          senderName: this.from,
          IdOfficine: this.idOfficine,
          Day: (new Date(this.selectDate)).getDate(),
          Month: (new Date(this.selectDate)).getMonth()
        }
        this.$vs.loading({ background: this.backgroundLoading, color: this.colorLoading, container: '#button-with-loading', scale: 0.45 })
        this.$store.dispatch('sms_marketing/sendAnnivClientSMSMessage', payload)
          .then((response) => {
            console.log(response.data)
            this.$vs.loading.close('#button-with-loading > .con-vs-loading')
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close('#button-with-loading > .con-vs-loading')
            this.$vs.notify({
              title: 'error',
              text: `${err.message}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })

      } else {
        this.$vs.notify({
          title: 'error',
          text: 'Aucun contact',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'danger'
        })
      }
    },
    getClientsWithBirthdayToday (_idOfficine, _selectDate) {
      this.$vs.loading({
        type:'sound'
      })
      const payload = {
        idOfficine: _idOfficine ? _idOfficine : this.idOfficine,
        selectDate: _selectDate ? _selectDate : this.selectDate
      }
      this.$store.dispatch('client/getClientsWithBirthdayToday', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getSmsConfig () {
      this.$store.dispatch('configs/getSmsConfigSenderNameSenderAddressMessageTemplate')
        .then((response) => {
          this.from = response.data.smsConfig ? response.data.smsConfig.senderName : null
          this.senderAddress = response.data.smsConfig ? response.data.smsConfig.senderAddress : null
          this.text = response.data.smsConfig ? response.data.smsConfig.messageAnnivClientTemplate : null
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine
      this.IdUser = this.activeUserInfos._id
      const selectDate = new Date()
      this.getClientsWithBirthdayToday(JSON.parse(localStorage.getItem('userInfo')).IdOfficine, selectDate)
    }
  },
  mounted () {
    this.destinations = this.clients ? this.clients.length > 0 ? this.clients.map((client) => {
      const indicatifPays = '225' // Remplacez par l'indicatif pays souhaité (sans le signe +)

      const telephone = client.Adresse && client.Adresse.mobile ? client.Adresse.mobile.startsWith(indicatifPays) ? client.Adresse.mobile : indicatifPays + client.Adresse.mobile : ''
      return {
        _id: client._id,
        nom: client.Nom,
        prenoms: client.Prenoms,
        telephone,
        email: client.Adresse ? client.Adresse.email : '',
        statusMessage: null,
        statusCode: null,
        resourceURL: null
      }
    }) : [] : []

  },
  created () {
    this.activeUserInfo()
    this.getSmsConfig()
  }
}
</script>
<style lang="scss" scoped>
.analyze-preview-container {
    width: 100%;
    text-align: center;
  }
  .analyze-preview-container .phone-container.sms.wl {
    background-image: url(https://d2ygi9hnn4h2ig.cloudfront.net/broadcast-sms/1.7.41/images/phone-sms-no-logo.png);
  }
  .analyze-preview-container .phone-container.sms {

    background-size: 100%;
    background-position: 50%;
    width: 322px;
    height: 634px;
    top: 120px;
   /*  left: calc(50% - 211px); */
    transform: translateX(-50%);
    margin-bottom: 120px;
    background-image: url(https://d2ygi9hnn4h2ig.cloudfront.net/broadcast-sms/1.7.41/images/phone-sms.png);
}

.analyze-preview-container .phone-content {
    position: absolute;
    top: 81px;
    left: 24px;
    width: 273px;
    height: 476px;
    overflow: hidden;
}

.analyze-preview-container .phone-container.sms .sender {
    margin-top: 50px;
}

.analyze-preview-container .phone-container.sms .msg-details {
    font-size: smaller;
}

.analyze-preview-container .phone-container.sms .msg-container {
    height: 318px;
    overflow: hidden;
    overflow-y: auto;
    overflow-wrap: break-word;
    word-break: break-word;
}

.analyze-preview-container .phone-container.sms .msg-content {
    background-color: #e6e6eb;
    text-align: left;
    padding: 5px 10px;
    margin: 5px 10px;
    border-radius: 20px;
    float: left;
    white-space: pre-wrap;
}
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 80vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>